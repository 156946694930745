<script setup lang="ts">
import { ConfigProvider } from "@/components/ui/config-provider";
import GlobeIcon from "@/icons/globe.svg?component";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	ScrollArea,
	ScrollAreaScrollbar,
	ScrollAreaViewport,
} from "@/components/ui/scroll-area";
import { getCurrentLocale, loadI18n } from "@/i18n/i18n.client";
import { LOCALES, LOCALE_NAMES } from "@/i18n/locales";
import Cookies from "js-cookie";

const props = defineProps<{
	localizedPageLinks: Record<Locale, string>;
	islandId: number;
}>();

const currentLocale = getCurrentLocale();
const { t } = await loadI18n(currentLocale);
</script>

<template>
	<ConfigProvider :island-id="props.islandId">
		<DropdownMenu :modal="false">
			<DropdownMenuTrigger
				:aria-label="t('labels.change_lang')"
				class="mx-1 flex items-center justify-start gap-x-1.5 whitespace-nowrap border-none bg-transparent px-2.5 py-2 text-sm text-stone-200 transition-colors hover:text-stone-50 data-[state=open]:border-white/10 xs:justify-end"
			>
				{{ currentLocale.toUpperCase() }}
				<GlobeIcon aria-hidden="true" class="size-4 shrink-0 stroke-[1.25]" />
			</DropdownMenuTrigger>
			<DropdownMenuContent position="popper" align="end" class="p-0">
				<ScrollArea>
					<ScrollAreaViewport class="max-h-56 p-1">
						<DropdownMenuCheckboxItem
							v-for="locale in LOCALES"
							:key="locale"
							:as="currentLocale === locale ? 'div' : 'a'"
							:href="localizedPageLinks[locale]"
							:value="locale"
							class="cursor-pointer"
							:checked="currentLocale === locale"
							@click="
								Cookies.set('locale', locale, { path: '/', expires: 365 })
							"
						>
							{{ LOCALE_NAMES[locale] }}
						</DropdownMenuCheckboxItem>
					</ScrollAreaViewport>
					<ScrollAreaScrollbar size="sm" orientation="vertical" />
				</ScrollArea>
			</DropdownMenuContent>
		</DropdownMenu>
	</ConfigProvider>
</template>
